export type IUserStatus = {
  token?: string;
  kyc_status?: string;
  is_new_user?: boolean;
  // is_get_kyc?: boolean;
};
export const useSession = () => {
  const store = useCookie<IUserStatus>("useUserStatusCookie");
  const state = useState<IUserStatus>("useUserStatusState", () => {
    return store.value ?? {};
  });
  return {
    setAuthToken(token: string) {
      state.value = { token };
      store.value = state.value;
    },
    getAuthToken() {
      return state.value.token;
    },
    setKycStatus(kyc_status: string) {
      state.value = { ...state.value, kyc_status };
      store.value = state.value;
    },
    getKycStatus() {
      return state.value?.kyc_status;
    },
    onNewUser() {
      state.value = { ...state.value, is_new_user: true };
      store.value = state.value;
    },
    isNewUser() {
      return !!state.value?.is_new_user;
    },
    getCookie() {
      return store.value;
    },
    clear() {
      store.value = {};
      state.value = {};
    },
  };
};
