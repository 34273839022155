import type { ModelRef } from "vue";

export const useWithdrawMethodsQuery = (
  enabled: boolean,
  fiat?: globalThis.Ref<string> | string
) => {
  return useQuery({
    queryKey: ["useWithdrawMethodsQuery", fiat],
    queryFn: () =>
      fetchByGet<Array<IWithdrawMethods>>("/bank/withdraw/methods", {
        transfer_type: "wire",
        ...(fiat ? { fiat: unref(fiat) } : {}),
      }),
    enabled,
  });
};

export const useDepositMethodsQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: ["useDepositMethodsQuery"],
    queryFn: () =>
      fetchByGet<Array<IDepositMethods>>("/bank/deposit/methods", {}),
    enabled,
  });
};

export const useWithdrawMutation = () => {
  return useMutation({
    mutationFn: (params: { amount: string; fiat: string; bank_id: string }) =>
      fetchByPost<
        [
          {
            result: string;
            message: string;
            amount_pay_to: string;
            asset_pay_to: string;
            reference: string;
          }
        ]
      >("/bank/withdraw", params),
  });
};

export const useWalletCryptoCurrencyListQuery = () => {
  return useQuery({
    queryKey: ["useWalletCryptoCurrencyListQuery"],
    queryFn: () =>
      fetchByGet<
        Array<
          Record<
            string,
            {
              chains: Array<{
                name: string;
                standard: string;
                standard_display: string;
                deposit_confirmation_num: string;
                est_deposit_arrival_time: string;
                withdraw_fee: string;
              }>;
              minimum_deposit: string;
              minimum_withdraw: string;
              maximum_withdraw: string;
              withdraw_limit_for_a_day: string;
            }
          >
        >
      >("/wallet/crypto/currency/list"),
  });
};

export const useWalletCryptoAddressQuery = (
  currency: string,
  chain: string
) => {
  return useQuery({
    queryKey: ["useWalletCryptoAddressQuery", currency, chain],
    queryFn: () =>
      fetchByGet<Array<{ address: string; address_qr: string }>>(
        "/wallet/crypto/address",
        {
          currency,
          chain,
        }
      ),
  });
};

export const useWalletCryptoAddressCheckQuery = (
  currency: string,
  chain: string,
  address: ModelRef<string | undefined, string>
) => {
  return useQuery({
    queryKey: ["useWalletCryptoAddressCheckQuery", currency, chain, address],
    queryFn: () =>
      fetchByGet<Array<{ result: boolean }>>(
        "/wallet/crypto/address/check",
        {
          currency,
          chain,
          address: unref(address),
        },
        { capture: true }
      ),
    enabled: false,
  });
};

export const useWalletContactsQuery = (currency: string, network: string) => {
  return useQuery({
    queryKey: ["useWalletContactsQuery", currency, network],
    queryFn: () =>
      fetchByGet<
        [
          Record<
            "list" | "favorite_list" | "recent_list",
            Array<{
              uuid: string;
              name: string;
              currency: string;
              address: string;
            }>
          >
        ]
      >("/wallet/contacts", {
        currency,
        network,
      }),
  });
};

export const useWalletContactMutation = () => {
  return useMutation({
    mutationFn: (params: { currency: string; name: string; address: string }) =>
      fetchByPost<[any]>("/wallet/contact", params),
  });
};

///wallet/crypto/send
export const useWalletCryptoSendMutation = (
  complete: (
    data: globalThis.DataT<
      [
        {
          secure: ISecure;
          verify_uuid: string;
        }
      ]
    >
  ) => void
) => {
  return useMutation({
    mutationFn: (params: {
      currency: string;
      chain: string;
      address: string;
      amount: string;
      verify_uuid?: string;
    }) =>
      fetchByPost<
        [
          {
            transaction_uuid: string;
            amount: 0.0025;
            currency: string;
            from: string;
            to: string;
            network: string;
            withdraw_fee: string;
            order_date: string;
          }
        ]
      >("/wallet/crypto/send", params),
    onSuccess: (data) => {
      complete(data as any);
    },
  });
};

///wallet/crypto/send/confirm
export const useWalletCryptoSendConfirmMutation = () => {
  return useMutation({
    mutationFn: (params: { transaction_uuid: string }) =>
      fetchByPost<[]>("/wallet/crypto/send/confirm", params),
  });
};
