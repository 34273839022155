import { GridComponent, TooltipComponent } from "echarts/components";

import { LineChart } from "echarts/charts";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
// // import ECharts modules manually to reduce bundle size
// import { use } from 'echarts/core'
import * as echarts from "echarts/core";

export default defineNuxtPlugin((nuxtApp) => {
  echarts.use([
    CanvasRenderer,
    SVGRenderer,
    LineChart,
    GridComponent,
    TooltipComponent,
  ]);
  nuxtApp.provide("echarts", echarts);
});
