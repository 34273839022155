import type { UseFetchOptions } from "#app";
import {
  SUCCESS_CODE,
  CLIENT_ERROR_CODE,
  SERVER_ERROR_CODE,
} from "@/utils/enum";

import { toast } from "vue3-toastify";

export type ErrorT = {
  code: CLIENT_ERROR_CODE | SERVER_ERROR_CODE;
  message: string;
  success: "success" | "error";
  errors: Record<string, any>[];
};

export interface DataT<T> {
  data: T;
  code: SUCCESS_CODE;
  message: string;
  success: "success" | "error";
}

export type OptionT<T> = Omit<
  UseFetchOptions<DataT<T>>,
  "method" | "query" | "body" | "params" | "headers" | "baseURL"
> & { capture?: boolean };

const handerResponse = (capture: boolean) => {
  const session = useSession();
  const modal = useModal();
  const router = useRouter();
  const route = useRoute();
  return {
    onRequest({ options }: any) {
      options.headers = new Headers(options.headers);
      // options.headers.set("Accept", "application/json");
      const token = session.getAuthToken();
      if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
      }
    },
    // 响应拦截
    onResponse({ response }: any) {
      if (response._data && response._data.status && import.meta.client) {
        const state = response._data.status;
        const code = response._data.code;
        if (state === "error") {
          const _data = response._data;
          switch (code) {
            case CLIENT_ERROR_CODE.UNAUTHENTICATED:
              modal.show({
                title: "Login Status Invalid",
                content:
                  "The current login status has expired, please log in again",
                btns: [
                  {
                    text: "Go to login",
                    onClick: () => {
                      router.push("/login");
                    },
                  },
                ],
              });
              break;
            case CLIENT_ERROR_CODE.UNAUTHENTICATED_BY_DEVICE_CHANGE:
              modal.show({
                title: "Login Status Invalid",
                content: response._data.message,
                btns: [
                  {
                    text: "Got it",
                    onClick: () => {
                      router.push("/");
                    },
                  },
                ],
              });
              break;
            case CLIENT_ERROR_CODE.USER_LOGIN_CREDENTIALS_INCORRECT:
              modal.show({
                content: response._data.message,
                btns: [
                  {
                    text: "Cancel",
                  },
                  {
                    text: "Forget Password",
                    onClick: () => {
                      router.push("/forgot");
                    },
                  },
                ],
              });
              _data.message = "";
              break;
            case CLIENT_ERROR_CODE.USER_EMAIL_NOT_EXIST:
              modal.show({
                content: response._data.message,
                btns: [
                  {
                    text: "Cancel",
                  },
                  {
                    text: "Sign Up",
                    onClick: () => {
                      router.push("/signup");
                    },
                  },
                ],
              });
              _data.message = "";
              break;
            case CLIENT_ERROR_CODE.GOOGLE_2FA_UNSET:
              router.push("/me/google");
              break;
            case CLIENT_ERROR_CODE.PHONE_2FA_UNSET:
              router.push("/me/phone");
              break;
            case CLIENT_ERROR_CODE.AT_LEAST_OPEN_ONE_SECURE_SETTING:
            case CLIENT_ERROR_CODE.USER_NOT_SUPPORT_INSTITUTION:
            case CLIENT_ERROR_CODE.VERIFICATION_EVENT_INVALID:
            case CLIENT_ERROR_CODE.SYSTEM_FORBIDDEN_REGION:
              // case CLIENT_ERROR_CODE.USER_EMAIL_NOT_EXIST:
              modal.show({
                content: response._data.message,
              });
              _data.message = "";
              break;
            default:
              console.log("capture", capture);
              if (!capture) {
                modal.show({
                  content: response._data.message,
                });
                _data.message = "";
              }
              break;
          }
          console.log("Promise.reject(_data)", _data);
          return Promise.reject(_data);
        } else if (state === "success") {
          switch (code) {
            case SUCCESS_CODE.UPGRADE_TOKEN:
              session.setAuthToken(response._data.data[0].token);
              break;
            case SUCCESS_CODE.USER_EMAIL_VERIFICATION_CODE_EXIST:
              toast.warn(response._data.message);
              break;
            case SUCCESS_CODE.LOGOUT:
              modal.show({
                title: "Login Status Invalid",
                content:
                  "The current login status has expired, please log in again",
                btns: [
                  {
                    text: "Go to login",
                    onClick: () => {
                      router.push("/login");
                    },
                  },
                ],
              });
              break;
            // case SUCCESS_CODE.USER_ACTION_NEED_VERIFY:
            //   if (route.meta.layout !== "un-auth") {
            //     const { secure_setting, verify_uuid } = response._data
            //       .data[0] as any;
            //     router.push({
            //       path: "/me/2fa",
            //       query: {
            //         verify_uuid: verify_uuid,
            //         secure_setting: JSON.stringify(secure_setting),
            //       },
            //     });
            //   }
            //   break;
          }
        }
      }
      // 成功返回
      return response._data;
    },
    // 错误处理
    onResponseError({ response }: any) {
      if (import.meta.client) {
        toast.error(response._data.message);
        return Promise.reject(null);
      } else {
        return Promise.reject(response._data);
      }
    },
  };
};

async function fetch<D extends Record<string, any>>(
  url: string,
  option: UseFetchOptions<DataT<D>> & { capture?: boolean }
) {
  const { capture, ..._options } = option;

  const storage = useGlobalStorage();
  const handerOptions = handerResponse(capture ?? false);

  return $fetch<DataT<D>>(url, {
    // @ts-ignore
    baseURL: storage.getBaseURL(),
    ..._options,
    ...handerOptions,
  });
}

export async function fetchByGet<D extends {}>(
  url: string,
  params?: Record<string, any>,
  options?: OptionT<D>
) {
  return fetch<D>(url, {
    method: "GET",
    params,
    headers: {
      Accept: "application/json",
    },
    ...options,
  });
}

export async function fetchByPost<D extends {}>(
  url: string,
  body?: Record<string, any>,
  options?: OptionT<D>
) {
  return fetch<D>(url, {
    method: "POST",
    body,
    headers: {
      Accept: "application/json",
    },
    ...options,
  });
}

export async function fetchByFile<D extends {}>(
  url: string,
  body: FormData,
  options?: OptionT<D>
) {
  return fetch<D>(url, {
    method: "POST",
    body: body,
    headers: {
      Accept: "application/json",
      // "Content-Type": "multipart/form-data",
    },
    ...options,
  });
}
