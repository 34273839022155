export default defineNuxtRouteMiddleware(async (to) => {
  // if (import.meta.client) return;
  // try {
  //   const pairsLists = usePairsListsState();
  //   const result = await fetchByGet<[{}]>("/common/pair/list");
  //   pairsLists.value = result.data[0];
  // } catch (error) {
  //   console.log("🚀 ~ defineNuxtRouteMiddleware ~ error:", error);
  //   throw error;
  // }
  if (to.path !== '/') {
    return navigateTo('/');
  }
});
