export const useTopUpBankQuery = (params: ITopUpBankParams) => {
  return useQuery({
    queryKey: ["useTopUpBankQuery", params],
    queryFn: () =>
      fetchByGet<[ITopUpBankData]>("/bank/deposit/account", params, {
        capture: true,
      }),
    enabled: false,
  });
};

export const useConfirmTopUpMutation = () => {
  return useMutation({
    mutationFn: (params: ITopUpConfirmParams) =>
      fetchByPost("/bank/deposit/confirm", params),
  });
};

export const useCashOutBanksQuery = (fiat: Ref<string>) => {
  return useQuery({
    queryKey: ["useCashOutBanksQuery", unref(fiat)],
    queryFn: () =>
      fetchByGet<[ICashOutBanksData]>("/bank/withdraw/account/list", {
        fiat: unref(fiat),
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useCashOutBankFieldsQuery = (fiat: Ref<string>) => {
  return useQuery({
    queryKey: ["useCashOutBankFieldsQuery", fiat],
    queryFn: () =>
      fetchByGet<[ICashOutBankAddProps]>("/bank/withdraw/account/fields", {
        fiat: unref(fiat),
      }),
    enabled: false,
  });
};

export const useConfirmCashOutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["useConfirmCashOutMutation"],
    mutationFn: (params: Record<string, string>) =>
      fetchByPost<[{ bank_id: string }]>("/bank/withdraw/account", params),
    onSuccess(data, variables, context) {
      const { currency } = variables;
      queryClient.invalidateQueries({
        queryKey: ["useCashOutBanksQuery", currency],
        refetchType: "all",
      });
    },
  });
};
