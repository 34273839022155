export const usePairsListsQuery = () => {
  return useQuery({
    queryKey: ["usePairsListsQuery"],
    queryFn: () =>
      fetchByGet<[{}]>(
        "/common/pair/list",
        {},
        {
          capture: true,
        }
      ),
    enabled: false,
  });
};

/**
 *
 * @param userStatus 用户的状态 enabled | disabled
 * @returns
 */
export const useBalanceQuery = (userStatus: string) => {
  return useQuery({
    queryKey: ["useBalanceQuery"],
    queryFn: () => fetchByGet<[Record<string, string>]>("/user/balance"),
    enabled: userStatus === "enabled",
  });
};

/**
 * @param userStatus 用户的状态 enabled | disabled
 * @param fiat 法币
 * @returns
 */
export const useBalanceFiatQuery = (
  userStatus: string,
  fiat?: Ref<string> | string
) => {
  return useQuery({
    queryKey: ["useBalanceFiatQuery", fiat],
    queryFn: () =>
      fetchByGet<[Record<string, string>]>("/user/balance/fiat", {
        fiat: unref(fiat),
      }),
    enabled: userStatus === "enabled",
  });
};

/**
 * @param userStatus 用户的状态 enabled | disabled
 * @param crypto 数字货币
 * @returns
 */
export const useBalanceCryptoQuery = (
  userStatus: string,
  crypto?: Ref<string>
) => {
  return useQuery({
    queryKey: ["useBalanceCryptoQuery", crypto],
    queryFn: () =>
      fetchByGet<[Record<string, string>]>("/user/balance/crypto", {
        crypto: unref(crypto),
      }),
    enabled: userStatus === "enabled",
  });
};

export const useRegionQuery = () => {
  return useQuery({
    queryKey: ["useRegionQuery"],
    queryFn: () =>
      fetchByGet<[Record<string, string[]>]>("/common/region/list", {}),
  });
};
