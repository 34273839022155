export const useGlobalStorage = () => {
  const {
    public: { baseURL },
  } = useRuntimeConfig();
  const store = useSessionStorage<{
    kyc: Partial<IKycInfo> | undefined;
    baseURL: string;
    enableHomeDialog: boolean;
    // enableNetWorkDialog: boolean;
    chainInfo?: { type: string; crypto?: string; chain?: string };
    topUpCurrency?: string;
    cashOutCurrency?: string;
  }>("useKycDataSession", {
    kyc: undefined,
    baseURL: baseURL as string,
    enableHomeDialog: true,
  });

  if (import.meta.dev) {
    store.value = { ...store.value, baseURL: baseURL as string };
  }

  return {
    setKyc(kyc: Partial<IKycInfo> | undefined, force?: boolean) {
      if (force) {
        store.value = { ...store.value, kyc };
      } else {
        store.value = {
          ...store.value,
          kyc: { ...((store.value.kyc ?? {}) as IKycInfo), ...(kyc ?? {}) },
        };
      }
    },
    getKyc() {
      return store.value.kyc;
    },
    setBaseURL(baseURL: string) {
      store.value = { ...store.value, baseURL };
    },
    getBaseURL() {
      return store.value.baseURL;
    },
    offHomeDialog() {
      store.value = { ...store.value, enableHomeDialog: false };
    },
    isShowHomeDialog() {
      return store.value.enableHomeDialog;
    },
    setTopUpCurrency(val?: string) {
      store.value = { ...store.value, topUpCurrency: val };
    },
    getTopUpCurrency() {
      return store.value.topUpCurrency;
    },
    setCashOutCurrency(val?: string) {
      store.value = { ...store.value, cashOutCurrency: val };
    },
    getCashOutCurrency() {
      return store.value.cashOutCurrency;
    },
    setChainInfo(info?: { type: string; crypto?: string; chain?: string }) {
      store.value = { ...store.value, chainInfo: info };
    },
    getChainInfo() {
      return store.value.chainInfo;
    },
    clear() {
      store.value = {
        kyc: undefined,
        baseURL: store.value.baseURL as string,
        enableHomeDialog: true,
      };
    },
  };
};
