export const useTransactionListMutation = () => {
  return useMutation({
    mutationFn: (params: ITransactionListParams) =>
      fetchByPost<ITransactionListResult[]>("/transaction/list", params),
  });
};

export const useTransactionDetailsQuery = (transaction_id: Ref<string>) => {
  return useQuery({
    queryKey: ["useTransactionDetailsQuery", transaction_id],
    queryFn: () =>
      fetchByGet<[ITransactionDetailsResult]>("/transaction/details", {
        transaction_id: unref(transaction_id),
      }),
  });
};
