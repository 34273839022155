type IModalMessage = {
  title?: string;
  content: string;
  btns?: Array<{
    text: string;
    type?: string;
    onClick?: () => void;
  }>;
} | null;
export const useModal = () => {
  const state = useState<IModalMessage>("useError", () => {
    return null;
  });
  // ...
  const show = (error: IModalMessage) => {
    state.value = error;
    (
      document.getElementById("global_error_modal") as HTMLDialogElement
    ).showModal();
  };

  const hide = () => {
    state.value = null;
    (
      document.getElementById("global_error_modal") as HTMLDialogElement
    ).close();
  };

  const getInfo = computed(() => state.value);

  return {
    show,
    hide,
    getInfo,
  };
};
