export const useQuestionsQuery = () => {
  return useQuery({
    queryKey: ["useQuestionsQuery"],
    queryFn: () => fetchByGet<IQuestion[][]>("/kyc/qas", {}),
  });
};
export const useAnswersQuery = () => {
  return useQuery({
    queryKey: ["useAnswersQuery"],
    queryFn: () => fetchByGet<Record<string, any>[][]>("/kyc/qa/answers", {}),
  });
};

export const useAnswerMutation = () => {
  return useMutation({
    mutationFn: (params: Array<Record<string, string>>) =>
      fetchByPost("/kyc/qas", params),
  });
};

export const useKycQuery = (verify_uuid?: string) => {
  return useQuery({
    queryKey: ["useKycQuery", verify_uuid],
    queryFn: () => fetchByGet<[IKycInfo]>("/kyc/info", { verify_uuid }),
    enabled: false,
  });
};

export const useKycMutation = () => {
  type IParams = Partial<
    Record<
      | "first_name"
      | "middle_name"
      | "last_name"
      | "birthdate"
      | "nationality"
      | "country"
      | "city"
      | "state"
      | "address_line_1"
      | "address_line_2"
      | "zipcode",
      string
    >
  >;
  return useMutation({
    mutationFn: (params: IParams) => fetchByPost("/kyc/info", params),
  });
};

export const useVeriffQuery = () => {
  return useQuery({
    queryKey: ["useVeriffQuery"],
    queryFn: () =>
      fetchByGet<[{ verification_url: string }]>("/kyc/verification/url", {}),
    enabled: false,
  });
};

export const useVeriffStatusQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: ["useVeriffStatusQuery", enabled],
    queryFn: () => fetchByGet<[{ kyc_status: string }]>("/kyc/status", {}),
    enabled,
  });
};

export const useRFIQuery = () => {
  return useQuery({
    queryKey: ["useRFIQuery"],
    queryFn: () =>
      fetchByGet<[ICurrentRFI]>("/kyc/rfi/current", {}, { capture: true }),
  });
};

export const useRFIIDLinkQuery = () => {
  return useQuery({
    queryKey: ["useRFIIDLinkQuery"],
    queryFn: () =>
      fetchByGet<[{ status: string; url: string }]>(
        "/kyc/rfi/verification",
        {}
      ),
    enabled: false,
  });
};

export const useRFIMutation = () => {
  return useMutation({
    mutationFn: (
      response: Array<{
        response_id: string;
        response?: {
          text: string;
          files: Array<{ file_hash: string; file_name: string }>;
        };
      }>
    ) => fetchByPost("/kyc/rfi/submit", { response }),
  });
};

export const useFileMutation = () => {
  return useMutation({
    mutationFn: (params: FormData) =>
      fetchByFile<[{ file_path: string; file_name: string }]>(
        "/kyc/rfi/files",
        params
      ),
  });
};
