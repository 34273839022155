export const useIpQuery = () => {
  return useQuery({
    queryKey: ["useIpQuery"],
    queryFn: () => fetchByGet<[{ ip: string }]>("/test/ip", {}),
    enabled: false,
  });
};

export const useSetIpMutation = () => {
  return useMutation({
    mutationFn: (params: { ip: string }) =>
      fetchByPost<[{}]>("/test/ip", params, {
        capture: true,
      }),
  });
};
