import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowBlackRight, LazySvgoArrowDropDown, LazySvgoArrowForward, LazySvgoAttachment, LazySvgoBack, LazySvgoBank, LazySvgoCardColorfulBg, LazySvgoCardGrayBg, LazySvgoCard, LazySvgoCashoutStart, LazySvgoCheck, LazySvgoCheckmarkWhiteS, LazySvgoCircleFailed, LazySvgoCircleSuccess, LazySvgoCircle, LazySvgoClose, LazySvgoContact, LazySvgoCopy, LazySvgoDelete, LazySvgoEmail, LazySvgoFilters, LazySvgoGooglePlay, LazySvgoGoogle, LazySvgoHomeInactive, LazySvgoIOS, LazySvgoLogo, LazySvgoNotifications, LazySvgoPadlock, LazySvgoPassport, LazySvgoPasswordHide, LazySvgoPasswordShow, LazySvgoPerson, LazySvgoQrcode, LazySvgoQuit, LazySvgoReceiveStart, LazySvgoReload, LazySvgoRight, LazySvgoSearch, LazySvgoSendStart, LazySvgoSmartphone, LazySvgoToastError, LazySvgoToastLoading, LazySvgoToastSuccess, LazySvgoToastWait, LazySvgoToastWarn, LazySvgoTopupStart, LazySvgoTradeInactive, LazySvgoUserIcon, LazySvgoWalletCash, LazySvgoWalletInactive } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowBlackRight", LazySvgoArrowBlackRight],
["SvgoArrowDropDown", LazySvgoArrowDropDown],
["SvgoArrowForward", LazySvgoArrowForward],
["SvgoAttachment", LazySvgoAttachment],
["SvgoBack", LazySvgoBack],
["SvgoBank", LazySvgoBank],
["SvgoCardColorfulBg", LazySvgoCardColorfulBg],
["SvgoCardGrayBg", LazySvgoCardGrayBg],
["SvgoCard", LazySvgoCard],
["SvgoCashoutStart", LazySvgoCashoutStart],
["SvgoCheck", LazySvgoCheck],
["SvgoCheckmarkWhiteS", LazySvgoCheckmarkWhiteS],
["SvgoCircleFailed", LazySvgoCircleFailed],
["SvgoCircleSuccess", LazySvgoCircleSuccess],
["SvgoCircle", LazySvgoCircle],
["SvgoClose", LazySvgoClose],
["SvgoContact", LazySvgoContact],
["SvgoCopy", LazySvgoCopy],
["SvgoDelete", LazySvgoDelete],
["SvgoEmail", LazySvgoEmail],
["SvgoFilters", LazySvgoFilters],
["SvgoGooglePlay", LazySvgoGooglePlay],
["SvgoGoogle", LazySvgoGoogle],
["SvgoHomeInactive", LazySvgoHomeInactive],
["SvgoIOS", LazySvgoIOS],
["SvgoLogo", LazySvgoLogo],
["SvgoNotifications", LazySvgoNotifications],
["SvgoPadlock", LazySvgoPadlock],
["SvgoPassport", LazySvgoPassport],
["SvgoPasswordHide", LazySvgoPasswordHide],
["SvgoPasswordShow", LazySvgoPasswordShow],
["SvgoPerson", LazySvgoPerson],
["SvgoQrcode", LazySvgoQrcode],
["SvgoQuit", LazySvgoQuit],
["SvgoReceiveStart", LazySvgoReceiveStart],
["SvgoReload", LazySvgoReload],
["SvgoRight", LazySvgoRight],
["SvgoSearch", LazySvgoSearch],
["SvgoSendStart", LazySvgoSendStart],
["SvgoSmartphone", LazySvgoSmartphone],
["SvgoToastError", LazySvgoToastError],
["SvgoToastLoading", LazySvgoToastLoading],
["SvgoToastSuccess", LazySvgoToastSuccess],
["SvgoToastWait", LazySvgoToastWait],
["SvgoToastWarn", LazySvgoToastWarn],
["SvgoTopupStart", LazySvgoTopupStart],
["SvgoTradeInactive", LazySvgoTradeInactive],
["SvgoUserIcon", LazySvgoUserIcon],
["SvgoWalletCash", LazySvgoWalletCash],
["SvgoWalletInactive", LazySvgoWalletInactive],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
