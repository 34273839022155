// To ensure the security of your account and assets, please complete  identity verification first.
// Your identity verification is under review and it is expected to take 1-2 working days to complete. For the security of your account and assets, this function is unavailable until your identity is verified. Thank you for your patience and we apologize for the inconvenience
// We regret that our services are not available for your account due to your identity verification failure
// Please resubmit your identity verification first. For the security of your account and assets, this function is unavailable until your identity is verified. Thank you for your patience and we apologize for the inconvenience

// We've detected that you've already completed identity verification in Legend. You do not need to undergo further verification at this time. You can now access all the services offered by Treasure Box directly!
// Sorry, your identity verification has been rejected. We are unable to provide services to you at the moment. We apologize for any inconvenience and appreciate your understanding.

export enum KycStatus {
  NotVerified = "Not verified", // NotVerified
  InReView = "In review",
  Rejected = "Rejected",
  NeedUpdate = "Need update",
  Verified = "Verified",
}

export const useUserInfo = (enabled?: boolean) => {
  const router = useRouter();
  const route = useRoute();
  const modal = useModal();
  const session = useSession();
  const storage = useGlobalStorage();

  const { user } = useApi();
  const { isPending, isLoading, isFetching, isSuccess, data, refetch, error } =
    user.useUserInfoQuery(enabled ?? false);
  const state = computed(() => data.value?.data[0].user);

  const kycStatus = computed(
    () => state.value?.kyc_status || KycStatus.NotVerified
  );

  const isLegendUser = computed(
    () => session.isNewUser() && unref(kycStatus) !== KycStatus.NotVerified
  );

  // watch(
  //   () => kycStatus.value,
  //   (newValue) => {
  //     console.log("session.setKycStatus(newValue)",newValue)
  //     session.setKycStatus(newValue);
  //   }
  // );
  // session.setKycStatus(kycStatus.value);

  // const $refetch = async () => {
  //   const { isSuccess } = await refetch();
  //   if (isSuccess) {
  //     session.setKycStatus(kycStatus.value);
  //   }
  // };

  const goToBeforeCheckKyc = (
    onVerified: (() => void) | (() => Promise<void>)
  ) => {
    switch (kycStatus.value) {
      case KycStatus.NotVerified:
        modal.show({
          content:
            "To ensure the security of your account and assets, please complete  identity verification first.",
          btns: [
            {
              text: "Verify now",
              onClick: () => {
                router.push(`/kyc?targetUrl=${route.fullPath}`);
              },
            },
          ],
        });
        break;
      case KycStatus.InReView:
        modal.show({
          title:
            "Your identity verification is under review and it is expected to take 1-2 working days to complete.",
          content:
            "For the security of your account and assets, this function is unavailable until your identity is verified. Thank you for your patience and we apologize for the inconvenience",
        });
        break;
      case KycStatus.Rejected:
        modal.show({
          content: unref(isLegendUser)
            ? "Sorry, your identity verification has been rejected. We are unable to provide services to you at the moment. We apologize for any inconvenience and appreciate your understanding."
            : "We regret that our services are not available for your account due to your identity verification failure.",
        });
        break;
      case KycStatus.NeedUpdate:
        modal.show({
          title: "Please resubmit your identity verification first.",
          content:
            "For the security of your account and assets, this function is unavailable until your identity is verified. Thank you for your patience and we apologize for the inconvenience",
          btns: [
            { text: "Cancel" },
            {
              text: "Resubmit",
              type: "primary",
              onClick: () => {
                router.push(`/rfi?targetUrl=${route.fullPath}`);
              },
            },
          ],
        });
        break;
      case KycStatus.Verified:
        onVerified();
        break;
    }
  };

  const openHomeDialog = () => {
    if (unref(isLegendUser) && storage.isShowHomeDialog()) {
      switch (kycStatus.value) {
        case KycStatus.Verified:
          modal.show({
            content:
              "We've detected that you've already completed identity verification in Legend. You do not need to undergo further verification at this time. You can now access all the services offered by Treasure Box directly!",
          });
          break;
        case KycStatus.Rejected:
          modal.show({
            content:
              "Sorry, your identity verification has been rejected. We are unable to provide services to you at the moment. We apologize for any inconvenience and appreciate your understanding.",
          });
          break;
        case KycStatus.NeedUpdate:
          modal.show({
            content:
              "We've detected that you previously completed identity verification in Legend. However, your identity information needs to be updated. Please resubmit your identity verification. We apologize for any inconvenience this may cause and appreciate your understanding.",
            btns: [
              { text: "Cancel" },
              {
                text: "Resubmit",
                type: "primary",
                onClick: () => {
                  router.push(`/rfi?targetUrl=${route.fullPath}`);
                },
              },
            ],
          });
          break;
      }
    }
    storage.offHomeDialog();
  };

  return {
    refetch,
    isPending,
    isLoading,
    isFetching,
    isSuccess,
    error,
    data: state,
    goToBeforeCheckKyc,
    kycStatus,
    openHomeDialog,
  };
};
