import type { OptionT, ErrorT } from "~/composables/useHttp";

/**
 * 用户邮箱注册
 * @param params
 * @param options
 * @returns
 */
export const useRegisterMutation = () => {
  return useMutation({
    mutationFn: (params: Record<"email", string>) =>
      fetchByPost("/user/register", params, { capture: true }),
  });
};

/**
 * 用户邮箱注册验证
 * @param params
 * @param options
 * @returns
 */
export const useRegisterVerifyMutation = (
  email: string | globalThis.Ref<string | undefined>
) => {
  return useMutation({
    mutationFn: (code: string) =>
      fetchByPost(
        "/user/register/verify",
        { code, email: unref(email) },
        { capture: true }
      ),
  });
};

/**
 * 注册保存密码
 * 验证完成 6.3
 * @param params
 * @param options
 * @returns
 */
export const usePasswordUpgradeMutation = () => {
  return useMutation({
    mutationFn: (params: Record<"password", string>) =>
      fetchByPost("/user/password", params, { capture: true }),
  });
};

/**
 * 用户登陆
 * 检查完毕 6.3
 * @param params
 * @param options
 * @returns
 */
export const useLoginMutation = () => {
  return useMutation({
    mutationFn: (params: Record<"username" | "password", string>) =>
      fetchByPost("/user/login", params, { capture: true }),
  });
};

/**
 * 用户登出
 * @returns
 */
export const useLogoutMutation = () => {
  return useMutation({
    mutationFn: () => fetchByPost("/user/logout"),
  });
};

/**
 * 忘记密码
 * @param params
 * @param options
 * @returns
 */
export const useForgotMutation = () => {
  return useMutation({
    mutationFn: (params: Record<"email", string>) =>
      fetchByPost("/user/password/reset", params, { capture: true }),
  });
};

/**
 * 忘记密码
 * @param params
 * @param options
 * @returns
 */
export const useForgotVerifyMutation = (
  email: globalThis.Ref<string | undefined>
) => {
  return useMutation({
    mutationFn: (code: string) =>
      fetchByPost<
        [
          {
            token: string;
          } & ISecure
        ]
      >(
        "/user/password/reset/verify",
        { code, email: unref(email) },
        { capture: true }
      ),
  });
};

/**
 * 获取用户基本信息
 * 检查完毕 6.3
 * @returns
 */

export const useUserInfoQuery = (enabled: boolean) => {
  const session = useSession();
  return useQuery({
    queryKey: ["useUserInfoQuery"],
    queryFn: async () => {
      const result = await fetchByGet<[{ user: IUserInfo }]>(
        "/user/account",
        {}
      );
      if (result?.data?.[0]?.user?.kyc_status) {
        session.setKycStatus(result.data[0].user.kyc_status);
      }
      return result;
    },
    enabled,
    retry: false,
  });
};

/**
 * 安全验证发送email
 * @param params
 * @param options
 * @returns
 */
export const useSendEmailMutation = () => {
  return useMutation({
    mutationFn: (email?: string) =>
      fetchByPost("/user/secure/email/send", email ? { email } : {}, {
        capture: true,
      }),
  });
};

/**
 * 安全验证发送 phone
 * @param params
 * @param options
 * @returns
 */
export const useSendPhoneMutation = () => {
  return useMutation({
    mutationFn: (_?: string) =>
      fetchByPost(
        "/user/secure/phone/send",
        {},
        {
          capture: true,
        }
      ),
  });
};

/**
 * 验证2fa
 * 验证通过 6.3
 * @param params
 * @param options
 * @returns
 */
export const use2faVerifyMutation = () => {
  const queryClient = useQueryClient();
  const modal = useModal();
  return useMutation({
    mutationFn: (params: {
      verify_uuid?: string;
      secure_verify: {
        ga_code?: string;
        email_code?: string;
        phone_code?: string;
      };
    }) =>
      fetchByPost("/user/secure/verify", params, {
        capture: true,
      }),
    // onError(error, variables, context) {
    //   const _error = unref(error) as unknown as ErrorT;
    //   const code = _error?.code;
    //   if (code) {
    //     if (
    //       ![
    //         CLIENT_ERROR_CODE.SECURE_EMAIL_VERIFY_FAIL,
    //         CLIENT_ERROR_CODE.SECURE_PHONE_VERIFY_FAIL,
    //         CLIENT_ERROR_CODE.SECURE_GOOGLE_AUTHENTICATOR_VERIFY_FAIL,
    //       ].includes(code as CLIENT_ERROR_CODE)
    //     ) {
    //       modal.show({
    //         content: unref(error)?.message!,
    //       });
    //     }
    //   }
    // },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["useUserInfoQuery"] });
    },
  });
};

/**
 * 获取 ga的key
 * 验证通过 6.3
 * @param options
 * @returns
 */
export const useGoogleKeyQuery = () => {
  return useQuery({
    queryKey: ["useGoogleKeyQuery"],
    queryFn: () =>
      fetchByGet<[{ secret_key: string; qr_code: string }]>(
        "/user/secure/ga",
        {},
        {
          capture: true,
        }
      ),
    enabled: false,
  });
};

/**
 * 验证 ga
 * @param options
 * @returns
 */
export const useGoogleVerifyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (code: string) =>
      fetchByPost("/user/secure/ga/verify", { code }, { capture: true }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["useUserInfoQuery"] });
    },
  });
};

/**
 * 设置2fa
 * @param options
 * @returns
 */
export const useSetting2FAMutation = (
  complete: (
    data: globalThis.DataT<
      [
        {
          secure: ISecure;
          verify_uuid: string;
        }
      ]
    >
  ) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: {
      two_factor?: boolean;
      email_verify?: boolean;
      phone_verify?: boolean;
    }) =>
      fetchByPost<
        [
          {
            secure: ISecure;
            verify_uuid: string;
          }
        ]
      >("/user/account/setting", { secure_setting: params }),
    onSuccess: (data) => {
      complete(data);
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["useUserInfoQuery"] });
    },
  });
};

/**
 * 确认旧密码
 * 验证完毕 6.3
 * @param params
 * @param options
 * @returns
 */
export const useOldPasswordVerifyMutation = () => {
  return useMutation({
    mutationFn: (password: string) =>
      fetchByPost("/user/password/change", { password }, { capture: true }),
  });
};

/**
 * 修改手机号码
 * @returns
 */
export const useSavePhoneMutation = () => {
  return useMutation({
    mutationFn: (
      params: Record<
        "phone_number" | "phone_country_code" | "phone_country_code_short",
        string
      >
    ) => fetchByPost("/user/phone/update", params),
  });
};

/**
 * 验证手机号码
 * @returns
 */
export const usePhoneVerifyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["useUserInfoQuery"] });
    },
    mutationFn: (code: string) =>
      fetchByPost("/user/phone/update/verify", { code }, { capture: true }),
  });
};
