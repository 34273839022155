import { default as indexvT9hGtrLxSMeta } from "/app/pages/forgot/index.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as detaillCqEP3L30kMeta } from "/app/pages/history/detail.vue?macro=true";
import { default as filters16fIEs9yhqMeta } from "/app/pages/history/filters.vue?macro=true";
import { default as index0s9avoLwFlMeta } from "/app/pages/history/index.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as address1PXEftnwKdMeta } from "/app/pages/kyc/address.vue?macro=true";
import { default as indexVMx5MPi3FZMeta } from "/app/pages/kyc/index.vue?macro=true";
import { default as informationXjn9tPCwpGMeta } from "/app/pages/kyc/information.vue?macro=true";
import { default as questionsQtpQVW8xUbMeta } from "/app/pages/kyc/questions.vue?macro=true";
import { default as verifyvaJaEVNq96Meta } from "/app/pages/kyc/verify.vue?macro=true";
import { default as kyccX0vjUai86Meta } from "/app/pages/kyc.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _2fajeGuYGJzn8Meta } from "/app/pages/me/2fa.vue?macro=true";
import { default as googleqk9FkUSyjHMeta } from "/app/pages/me/google.vue?macro=true";
import { default as indexnwyNS3d1VsMeta } from "/app/pages/me/index.vue?macro=true";
import { default as comfirmTdupimU9ftMeta } from "/app/pages/me/password/comfirm.vue?macro=true";
import { default as indexZII0yEkfX4Meta } from "/app/pages/me/password/index.vue?macro=true";
import { default as phoneWg1b12YaFUMeta } from "/app/pages/me/phone.vue?macro=true";
import { default as settingsvzP2L4d0HUMeta } from "/app/pages/me/settings.vue?macro=true";
import { default as meQzFFSRzs0uMeta } from "/app/pages/me.vue?macro=true";
import { default as _91status_936ulIThRnWyMeta } from "/app/pages/payments/card/[status].vue?macro=true";
import { default as address3331ppP5OdMeta } from "/app/pages/payments/card/address.vue?macro=true";
import { default as details98CjXxE4K3Meta } from "/app/pages/payments/card/details.vue?macro=true";
import { default as add_45_91currency_93hoH9tmgQhGMeta } from "/app/pages/payments/cashout/bank/add-[currency].vue?macro=true";
import { default as confirm7izFcoRSQOMeta } from "/app/pages/payments/cashout/bank/confirm.vue?macro=true";
import { default as index4DIviTw3fSMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as index01BppW0HJLMeta } from "/app/pages/payments/management/index.vue?macro=true";
import { default as _91currency_931UHu3v3Xc9Meta } from "/app/pages/payments/topup/wire/[currency].vue?macro=true";
import { default as rfi05h6uKfvswMeta } from "/app/pages/rfi.vue?macro=true";
import { default as indexxLsScIFze3Meta } from "/app/pages/signup/index.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as _91status_9354AQZAsfU5Meta } from "/app/pages/trade/[side]/[status].vue?macro=true";
import { default as confirmhd6VxHk4QKMeta } from "/app/pages/trade/[side]/confirm.vue?macro=true";
import { default as indexvOOJUaP9I1Meta } from "/app/pages/trade/[side]/index.vue?macro=true";
import { default as payment2d6I7vjWNKMeta } from "/app/pages/trade/[side]/payment.vue?macro=true";
import { default as tradeaTbD2kYxp1Meta } from "/app/pages/trade.vue?macro=true";
import { default as confirmhiOClghMZcMeta } from "/app/pages/wallet/cash/[type]/[currency]/cashout/confirm.vue?macro=true";
import { default as indexzpxRdMeULgMeta } from "/app/pages/wallet/cash/[type]/[currency]/cashout/index.vue?macro=true";
import { default as indexrE0WNVDBu9Meta } from "/app/pages/wallet/cash/[type]/[currency]/index.vue?macro=true";
import { default as indexj4yzODZth5Meta } from "/app/pages/wallet/cash/[type]/index.vue?macro=true";
import { default as indexyiwfZfk0UgMeta } from "/app/pages/wallet/cash/index.vue?macro=true";
import { default as indexv3uDIfa2sGMeta } from "/app/pages/wallet/crypto/[type]/[crypto]/index.vue?macro=true";
import { default as confirmoQ56ILoPgeMeta } from "/app/pages/wallet/crypto/[type]/[crypto]/send/confirm.vue?macro=true";
import { default as contantsyWZMRnZkuhMeta } from "/app/pages/wallet/crypto/[type]/[crypto]/send/contants.vue?macro=true";
import { default as withdraw0FkjutAfYBMeta } from "/app/pages/wallet/crypto/[type]/[crypto]/send/withdraw.vue?macro=true";
import { default as indexx7xt2vNO2mMeta } from "/app/pages/wallet/crypto/[type]/index.vue?macro=true";
import { default as indexq2fwf10mmCMeta } from "/app/pages/wallet/crypto/index.vue?macro=true";
import { default as indexzk34bxS2TyMeta } from "/app/pages/wallet/index.vue?macro=true";
import { default as walletfANoq5LO2uMeta } from "/app/pages/wallet.vue?macro=true";
export default [
  {
    name: forgotNziWY5IfaeMeta?.name,
    path: "/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue").then(m => m.default || m),
    children: [
  {
    name: "forgot___en___default",
    path: "",
    component: () => import("/app/pages/forgot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgotNziWY5IfaeMeta?.name,
    path: "/en/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue").then(m => m.default || m),
    children: [
  {
    name: "forgot___en",
    path: "",
    component: () => import("/app/pages/forgot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgotNziWY5IfaeMeta?.name,
    path: "/zh_Hans/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue").then(m => m.default || m),
    children: [
  {
    name: "forgot___zh_Hans",
    path: "",
    component: () => import("/app/pages/forgot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "history-detail___en___default",
    path: "/history/detail",
    meta: detaillCqEP3L30kMeta || {},
    component: () => import("/app/pages/history/detail.vue").then(m => m.default || m)
  },
  {
    name: "history-detail___en",
    path: "/en/history/detail",
    meta: detaillCqEP3L30kMeta || {},
    component: () => import("/app/pages/history/detail.vue").then(m => m.default || m)
  },
  {
    name: "history-detail___zh_Hans",
    path: "/zh_Hans/history/detail",
    meta: detaillCqEP3L30kMeta || {},
    component: () => import("/app/pages/history/detail.vue").then(m => m.default || m)
  },
  {
    name: "history-filters___en___default",
    path: "/history/filters",
    meta: filters16fIEs9yhqMeta || {},
    component: () => import("/app/pages/history/filters.vue").then(m => m.default || m)
  },
  {
    name: "history-filters___en",
    path: "/en/history/filters",
    meta: filters16fIEs9yhqMeta || {},
    component: () => import("/app/pages/history/filters.vue").then(m => m.default || m)
  },
  {
    name: "history-filters___zh_Hans",
    path: "/zh_Hans/history/filters",
    meta: filters16fIEs9yhqMeta || {},
    component: () => import("/app/pages/history/filters.vue").then(m => m.default || m)
  },
  {
    name: "history___en___default",
    path: "/history",
    meta: index0s9avoLwFlMeta || {},
    component: () => import("/app/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___en",
    path: "/en/history",
    meta: index0s9avoLwFlMeta || {},
    component: () => import("/app/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history___zh_Hans",
    path: "/zh_Hans/history",
    meta: index0s9avoLwFlMeta || {},
    component: () => import("/app/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "home___en___default",
    path: "/home",
    meta: homeH3NsyxtJwrMeta || {},
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/en/home",
    meta: homeH3NsyxtJwrMeta || {},
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "home___zh_Hans",
    path: "/zh_Hans/home",
    meta: homeH3NsyxtJwrMeta || {},
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh_Hans",
    path: "/zh_Hans",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kyccX0vjUai86Meta?.name,
    path: "/kyc",
    meta: kyccX0vjUai86Meta || {},
    component: () => import("/app/pages/kyc.vue").then(m => m.default || m),
    children: [
  {
    name: "kyc-address___en___default",
    path: "address",
    component: () => import("/app/pages/kyc/address.vue").then(m => m.default || m)
  },
  {
    name: "kyc___en___default",
    path: "",
    component: () => import("/app/pages/kyc/index.vue").then(m => m.default || m)
  },
  {
    name: "kyc-information___en___default",
    path: "information",
    component: () => import("/app/pages/kyc/information.vue").then(m => m.default || m)
  },
  {
    name: "kyc-questions___en___default",
    path: "questions",
    component: () => import("/app/pages/kyc/questions.vue").then(m => m.default || m)
  },
  {
    name: "kyc-verify___en___default",
    path: "verify",
    component: () => import("/app/pages/kyc/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: kyccX0vjUai86Meta?.name,
    path: "/en/kyc",
    meta: kyccX0vjUai86Meta || {},
    component: () => import("/app/pages/kyc.vue").then(m => m.default || m),
    children: [
  {
    name: "kyc-address___en",
    path: "address",
    component: () => import("/app/pages/kyc/address.vue").then(m => m.default || m)
  },
  {
    name: "kyc___en",
    path: "",
    component: () => import("/app/pages/kyc/index.vue").then(m => m.default || m)
  },
  {
    name: "kyc-information___en",
    path: "information",
    component: () => import("/app/pages/kyc/information.vue").then(m => m.default || m)
  },
  {
    name: "kyc-questions___en",
    path: "questions",
    component: () => import("/app/pages/kyc/questions.vue").then(m => m.default || m)
  },
  {
    name: "kyc-verify___en",
    path: "verify",
    component: () => import("/app/pages/kyc/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: kyccX0vjUai86Meta?.name,
    path: "/zh_Hans/kyc",
    meta: kyccX0vjUai86Meta || {},
    component: () => import("/app/pages/kyc.vue").then(m => m.default || m),
    children: [
  {
    name: "kyc-address___zh_Hans",
    path: "address",
    component: () => import("/app/pages/kyc/address.vue").then(m => m.default || m)
  },
  {
    name: "kyc___zh_Hans",
    path: "",
    component: () => import("/app/pages/kyc/index.vue").then(m => m.default || m)
  },
  {
    name: "kyc-information___zh_Hans",
    path: "information",
    component: () => import("/app/pages/kyc/information.vue").then(m => m.default || m)
  },
  {
    name: "kyc-questions___zh_Hans",
    path: "questions",
    component: () => import("/app/pages/kyc/questions.vue").then(m => m.default || m)
  },
  {
    name: "kyc-verify___zh_Hans",
    path: "verify",
    component: () => import("/app/pages/kyc/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginhHM0vSTW5jMeta?.name,
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login___en___default",
    path: "",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginhHM0vSTW5jMeta?.name,
    path: "/en/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login___en",
    path: "",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginhHM0vSTW5jMeta?.name,
    path: "/zh_Hans/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login___zh_Hans",
    path: "",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: meQzFFSRzs0uMeta?.name,
    path: "/me",
    meta: meQzFFSRzs0uMeta || {},
    component: () => import("/app/pages/me.vue").then(m => m.default || m),
    children: [
  {
    name: "me-2fa___en___default",
    path: "2fa",
    component: () => import("/app/pages/me/2fa.vue").then(m => m.default || m)
  },
  {
    name: "me-google___en___default",
    path: "google",
    component: () => import("/app/pages/me/google.vue").then(m => m.default || m)
  },
  {
    name: "me___en___default",
    path: "",
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: "me-password-comfirm___en___default",
    path: "password/comfirm",
    component: () => import("/app/pages/me/password/comfirm.vue").then(m => m.default || m)
  },
  {
    name: "me-password___en___default",
    path: "password",
    component: () => import("/app/pages/me/password/index.vue").then(m => m.default || m)
  },
  {
    name: "me-phone___en___default",
    path: "phone",
    component: () => import("/app/pages/me/phone.vue").then(m => m.default || m)
  },
  {
    name: "me-settings___en___default",
    path: "settings",
    component: () => import("/app/pages/me/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: meQzFFSRzs0uMeta?.name,
    path: "/en/me",
    meta: meQzFFSRzs0uMeta || {},
    component: () => import("/app/pages/me.vue").then(m => m.default || m),
    children: [
  {
    name: "me-2fa___en",
    path: "2fa",
    component: () => import("/app/pages/me/2fa.vue").then(m => m.default || m)
  },
  {
    name: "me-google___en",
    path: "google",
    component: () => import("/app/pages/me/google.vue").then(m => m.default || m)
  },
  {
    name: "me___en",
    path: "",
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: "me-password-comfirm___en",
    path: "password/comfirm",
    component: () => import("/app/pages/me/password/comfirm.vue").then(m => m.default || m)
  },
  {
    name: "me-password___en",
    path: "password",
    component: () => import("/app/pages/me/password/index.vue").then(m => m.default || m)
  },
  {
    name: "me-phone___en",
    path: "phone",
    component: () => import("/app/pages/me/phone.vue").then(m => m.default || m)
  },
  {
    name: "me-settings___en",
    path: "settings",
    component: () => import("/app/pages/me/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: meQzFFSRzs0uMeta?.name,
    path: "/zh_Hans/me",
    meta: meQzFFSRzs0uMeta || {},
    component: () => import("/app/pages/me.vue").then(m => m.default || m),
    children: [
  {
    name: "me-2fa___zh_Hans",
    path: "2fa",
    component: () => import("/app/pages/me/2fa.vue").then(m => m.default || m)
  },
  {
    name: "me-google___zh_Hans",
    path: "google",
    component: () => import("/app/pages/me/google.vue").then(m => m.default || m)
  },
  {
    name: "me___zh_Hans",
    path: "",
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: "me-password-comfirm___zh_Hans",
    path: "password/comfirm",
    component: () => import("/app/pages/me/password/comfirm.vue").then(m => m.default || m)
  },
  {
    name: "me-password___zh_Hans",
    path: "password",
    component: () => import("/app/pages/me/password/index.vue").then(m => m.default || m)
  },
  {
    name: "me-phone___zh_Hans",
    path: "phone",
    component: () => import("/app/pages/me/phone.vue").then(m => m.default || m)
  },
  {
    name: "me-settings___zh_Hans",
    path: "settings",
    component: () => import("/app/pages/me/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-card-status___en___default",
    path: "/payments/card/:status()",
    meta: _91status_936ulIThRnWyMeta || {},
    component: () => import("/app/pages/payments/card/[status].vue").then(m => m.default || m)
  },
  {
    name: "payments-card-status___en",
    path: "/en/payments/card/:status()",
    meta: _91status_936ulIThRnWyMeta || {},
    component: () => import("/app/pages/payments/card/[status].vue").then(m => m.default || m)
  },
  {
    name: "payments-card-status___zh_Hans",
    path: "/zh_Hans/payments/card/:status()",
    meta: _91status_936ulIThRnWyMeta || {},
    component: () => import("/app/pages/payments/card/[status].vue").then(m => m.default || m)
  },
  {
    name: "payments-card-address___en___default",
    path: "/payments/card/address",
    meta: address3331ppP5OdMeta || {},
    component: () => import("/app/pages/payments/card/address.vue").then(m => m.default || m)
  },
  {
    name: "payments-card-address___en",
    path: "/en/payments/card/address",
    meta: address3331ppP5OdMeta || {},
    component: () => import("/app/pages/payments/card/address.vue").then(m => m.default || m)
  },
  {
    name: "payments-card-address___zh_Hans",
    path: "/zh_Hans/payments/card/address",
    meta: address3331ppP5OdMeta || {},
    component: () => import("/app/pages/payments/card/address.vue").then(m => m.default || m)
  },
  {
    name: "payments-card-details___en___default",
    path: "/payments/card/details",
    meta: details98CjXxE4K3Meta || {},
    component: () => import("/app/pages/payments/card/details.vue").then(m => m.default || m)
  },
  {
    name: "payments-card-details___en",
    path: "/en/payments/card/details",
    meta: details98CjXxE4K3Meta || {},
    component: () => import("/app/pages/payments/card/details.vue").then(m => m.default || m)
  },
  {
    name: "payments-card-details___zh_Hans",
    path: "/zh_Hans/payments/card/details",
    meta: details98CjXxE4K3Meta || {},
    component: () => import("/app/pages/payments/card/details.vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-add-currency___en___default",
    path: "/payments/cashout/bank/add-:currency()",
    meta: add_45_91currency_93hoH9tmgQhGMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/add-[currency].vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-add-currency___en",
    path: "/en/payments/cashout/bank/add-:currency()",
    meta: add_45_91currency_93hoH9tmgQhGMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/add-[currency].vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-add-currency___zh_Hans",
    path: "/zh_Hans/payments/cashout/bank/add-:currency()",
    meta: add_45_91currency_93hoH9tmgQhGMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/add-[currency].vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-confirm___en___default",
    path: "/payments/cashout/bank/confirm",
    meta: confirm7izFcoRSQOMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/confirm.vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-confirm___en",
    path: "/en/payments/cashout/bank/confirm",
    meta: confirm7izFcoRSQOMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/confirm.vue").then(m => m.default || m)
  },
  {
    name: "payments-cashout-bank-confirm___zh_Hans",
    path: "/zh_Hans/payments/cashout/bank/confirm",
    meta: confirm7izFcoRSQOMeta || {},
    component: () => import("/app/pages/payments/cashout/bank/confirm.vue").then(m => m.default || m)
  },
  {
    name: "payments___en___default",
    path: "/payments",
    meta: index4DIviTw3fSMeta || {},
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___en",
    path: "/en/payments",
    meta: index4DIviTw3fSMeta || {},
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___zh_Hans",
    path: "/zh_Hans/payments",
    meta: index4DIviTw3fSMeta || {},
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management___en___default",
    path: "/payments/management",
    meta: index01BppW0HJLMeta || {},
    component: () => import("/app/pages/payments/management/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management___en",
    path: "/en/payments/management",
    meta: index01BppW0HJLMeta || {},
    component: () => import("/app/pages/payments/management/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management___zh_Hans",
    path: "/zh_Hans/payments/management",
    meta: index01BppW0HJLMeta || {},
    component: () => import("/app/pages/payments/management/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-topup-wire-currency___en___default",
    path: "/payments/topup/wire/:currency()",
    meta: _91currency_931UHu3v3Xc9Meta || {},
    component: () => import("/app/pages/payments/topup/wire/[currency].vue").then(m => m.default || m)
  },
  {
    name: "payments-topup-wire-currency___en",
    path: "/en/payments/topup/wire/:currency()",
    meta: _91currency_931UHu3v3Xc9Meta || {},
    component: () => import("/app/pages/payments/topup/wire/[currency].vue").then(m => m.default || m)
  },
  {
    name: "payments-topup-wire-currency___zh_Hans",
    path: "/zh_Hans/payments/topup/wire/:currency()",
    meta: _91currency_931UHu3v3Xc9Meta || {},
    component: () => import("/app/pages/payments/topup/wire/[currency].vue").then(m => m.default || m)
  },
  {
    name: "rfi___en___default",
    path: "/rfi",
    meta: rfi05h6uKfvswMeta || {},
    component: () => import("/app/pages/rfi.vue").then(m => m.default || m)
  },
  {
    name: "rfi___en",
    path: "/en/rfi",
    meta: rfi05h6uKfvswMeta || {},
    component: () => import("/app/pages/rfi.vue").then(m => m.default || m)
  },
  {
    name: "rfi___zh_Hans",
    path: "/zh_Hans/rfi",
    meta: rfi05h6uKfvswMeta || {},
    component: () => import("/app/pages/rfi.vue").then(m => m.default || m)
  },
  {
    name: signupezBbZGXKU6Meta?.name,
    path: "/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: "signup___en___default",
    path: "",
    component: () => import("/app/pages/signup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signupezBbZGXKU6Meta?.name,
    path: "/en/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: "signup___en",
    path: "",
    component: () => import("/app/pages/signup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signupezBbZGXKU6Meta?.name,
    path: "/zh_Hans/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: "signup___zh_Hans",
    path: "",
    component: () => import("/app/pages/signup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade___en___default",
    path: "/trade",
    meta: tradeaTbD2kYxp1Meta || {},
    component: () => import("/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-side-status___en___default",
    path: ":side()/:status()",
    meta: _91status_9354AQZAsfU5Meta || {},
    component: () => import("/app/pages/trade/[side]/[status].vue").then(m => m.default || m)
  },
  {
    name: "trade-side-confirm___en___default",
    path: ":side()/confirm",
    meta: confirmhd6VxHk4QKMeta || {},
    component: () => import("/app/pages/trade/[side]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "trade-side___en___default",
    path: ":side()",
    meta: indexvOOJUaP9I1Meta || {},
    component: () => import("/app/pages/trade/[side]/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-side-payment___en___default",
    path: ":side()/payment",
    meta: payment2d6I7vjWNKMeta || {},
    component: () => import("/app/pages/trade/[side]/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade___en",
    path: "/en/trade",
    meta: tradeaTbD2kYxp1Meta || {},
    component: () => import("/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-side-status___en",
    path: ":side()/:status()",
    meta: _91status_9354AQZAsfU5Meta || {},
    component: () => import("/app/pages/trade/[side]/[status].vue").then(m => m.default || m)
  },
  {
    name: "trade-side-confirm___en",
    path: ":side()/confirm",
    meta: confirmhd6VxHk4QKMeta || {},
    component: () => import("/app/pages/trade/[side]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "trade-side___en",
    path: ":side()",
    meta: indexvOOJUaP9I1Meta || {},
    component: () => import("/app/pages/trade/[side]/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-side-payment___en",
    path: ":side()/payment",
    meta: payment2d6I7vjWNKMeta || {},
    component: () => import("/app/pages/trade/[side]/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade___zh_Hans",
    path: "/zh_Hans/trade",
    meta: tradeaTbD2kYxp1Meta || {},
    component: () => import("/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-side-status___zh_Hans",
    path: ":side()/:status()",
    meta: _91status_9354AQZAsfU5Meta || {},
    component: () => import("/app/pages/trade/[side]/[status].vue").then(m => m.default || m)
  },
  {
    name: "trade-side-confirm___zh_Hans",
    path: ":side()/confirm",
    meta: confirmhd6VxHk4QKMeta || {},
    component: () => import("/app/pages/trade/[side]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "trade-side___zh_Hans",
    path: ":side()",
    meta: indexvOOJUaP9I1Meta || {},
    component: () => import("/app/pages/trade/[side]/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-side-payment___zh_Hans",
    path: ":side()/payment",
    meta: payment2d6I7vjWNKMeta || {},
    component: () => import("/app/pages/trade/[side]/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: walletfANoq5LO2uMeta?.name,
    path: "/wallet",
    meta: walletfANoq5LO2uMeta || {},
    component: () => import("/app/pages/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: "wallet-cash-type-currency-cashout-confirm___en___default",
    path: "cash/:type()/:currency()/cashout/confirm",
    meta: confirmhiOClghMZcMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency-cashout___en___default",
    path: "cash/:type()/:currency()/cashout",
    meta: indexzpxRdMeULgMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency___en___default",
    path: "cash/:type()/:currency()",
    meta: indexrE0WNVDBu9Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type___en___default",
    path: "cash/:type()",
    meta: indexj4yzODZth5Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash___en___default",
    path: "cash",
    meta: indexyiwfZfk0UgMeta || {},
    component: () => import("/app/pages/wallet/cash/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto___en___default",
    path: "crypto/:type()/:crypto()",
    meta: indexv3uDIfa2sGMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-confirm___en___default",
    path: "crypto/:type()/:crypto()/send/confirm",
    meta: confirmoQ56ILoPgeMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-contants___en___default",
    path: "crypto/:type()/:crypto()/send/contants",
    meta: contantsyWZMRnZkuhMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/contants.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-withdraw___en___default",
    path: "crypto/:type()/:crypto()/send/withdraw",
    meta: withdraw0FkjutAfYBMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type___en___default",
    path: "crypto/:type()",
    meta: indexx7xt2vNO2mMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto___en___default",
    path: "crypto",
    meta: indexq2fwf10mmCMeta || {},
    component: () => import("/app/pages/wallet/crypto/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet___en___default",
    path: "",
    meta: indexzk34bxS2TyMeta || {},
    component: () => import("/app/pages/wallet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: walletfANoq5LO2uMeta?.name,
    path: "/en/wallet",
    meta: walletfANoq5LO2uMeta || {},
    component: () => import("/app/pages/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: "wallet-cash-type-currency-cashout-confirm___en",
    path: "cash/:type()/:currency()/cashout/confirm",
    meta: confirmhiOClghMZcMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency-cashout___en",
    path: "cash/:type()/:currency()/cashout",
    meta: indexzpxRdMeULgMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency___en",
    path: "cash/:type()/:currency()",
    meta: indexrE0WNVDBu9Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type___en",
    path: "cash/:type()",
    meta: indexj4yzODZth5Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash___en",
    path: "cash",
    meta: indexyiwfZfk0UgMeta || {},
    component: () => import("/app/pages/wallet/cash/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto___en",
    path: "crypto/:type()/:crypto()",
    meta: indexv3uDIfa2sGMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-confirm___en",
    path: "crypto/:type()/:crypto()/send/confirm",
    meta: confirmoQ56ILoPgeMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-contants___en",
    path: "crypto/:type()/:crypto()/send/contants",
    meta: contantsyWZMRnZkuhMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/contants.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-withdraw___en",
    path: "crypto/:type()/:crypto()/send/withdraw",
    meta: withdraw0FkjutAfYBMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type___en",
    path: "crypto/:type()",
    meta: indexx7xt2vNO2mMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto___en",
    path: "crypto",
    meta: indexq2fwf10mmCMeta || {},
    component: () => import("/app/pages/wallet/crypto/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet___en",
    path: "",
    meta: indexzk34bxS2TyMeta || {},
    component: () => import("/app/pages/wallet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: walletfANoq5LO2uMeta?.name,
    path: "/zh_Hans/wallet",
    meta: walletfANoq5LO2uMeta || {},
    component: () => import("/app/pages/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: "wallet-cash-type-currency-cashout-confirm___zh_Hans",
    path: "cash/:type()/:currency()/cashout/confirm",
    meta: confirmhiOClghMZcMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency-cashout___zh_Hans",
    path: "cash/:type()/:currency()/cashout",
    meta: indexzpxRdMeULgMeta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/cashout/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type-currency___zh_Hans",
    path: "cash/:type()/:currency()",
    meta: indexrE0WNVDBu9Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/[currency]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash-type___zh_Hans",
    path: "cash/:type()",
    meta: indexj4yzODZth5Meta || {},
    component: () => import("/app/pages/wallet/cash/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cash___zh_Hans",
    path: "cash",
    meta: indexyiwfZfk0UgMeta || {},
    component: () => import("/app/pages/wallet/cash/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto___zh_Hans",
    path: "crypto/:type()/:crypto()",
    meta: indexv3uDIfa2sGMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-confirm___zh_Hans",
    path: "crypto/:type()/:crypto()/send/confirm",
    meta: confirmoQ56ILoPgeMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/confirm.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-contants___zh_Hans",
    path: "crypto/:type()/:crypto()/send/contants",
    meta: contantsyWZMRnZkuhMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/contants.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type-crypto-send-withdraw___zh_Hans",
    path: "crypto/:type()/:crypto()/send/withdraw",
    meta: withdraw0FkjutAfYBMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/[crypto]/send/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto-type___zh_Hans",
    path: "crypto/:type()",
    meta: indexx7xt2vNO2mMeta || {},
    component: () => import("/app/pages/wallet/crypto/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-crypto___zh_Hans",
    path: "crypto",
    meta: indexq2fwf10mmCMeta || {},
    component: () => import("/app/pages/wallet/crypto/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet___zh_Hans",
    path: "",
    meta: indexzk34bxS2TyMeta || {},
    component: () => import("/app/pages/wallet/index.vue").then(m => m.default || m)
  }
]
  }
]