import * as common from "./common";
import * as user from "./user";
import * as kyc from "./kyc";
import * as trade from "./trade";
import * as payments from "./payments";
import * as wallet from "./wallet";
import * as ip from "./ip";
import * as transaction from "./transaction";

export default {
  user,
  common,
  kyc,
  trade,
  payments,
  wallet,
  ip,
  transaction,
};
