export enum SUCCESS_CODE {
  SUCCESS = "100000",
  USER_EMAIL_VERIFICATION_CODE_EXIST = "110001",
  LOGOUT = "110002",
  UPGRADE_TOKEN = "120000",
  USER_ACTION_NEED_VERIFY = "120001",
}

export enum SERVER_ERROR_CODE {
  DEFAULT = "900000",
  USER_LOGIN_FAIL = "910001",
  USER_REGISTER_FAIL = "910002",
  TWO_FACTOR_GENERATE_KEY_FAIL = "910003",
  TWO_FACTOR_ENABLE_FAIL = "910004",
  CHANGE_PASSWORD_FAIL = "910005",
  VERIFY_EVENT_ACTION_INVALID = "910006",
  CREATE_VERIFICATION_CODE_FAILED = "910007",
  TWO_FACTOR_SAVE_KEY_FAIL = "910008",
  TWO_FACTOR_VERIFY_FAIL = "910009",
}

export enum CLIENT_ERROR_CODE {
  DEFAULT = "200001",

  REQUEST_LIMIT = "200003",

  UNAUTHENTICATED = "210001",

  SYSTEM_FORBIDDEN_REGION = "210002", // 服务不可用

  SEND_CODE_FAIL = "210003",

  RESEND_CODE_FAIL = "210004",

  SEND_CODE_TIME_LIMIT = "210005",

  SEND_CODE_COUNT_LIMIT = "210006",

  VERIFY_TWO_FACTOR_CODE_FAIL = "210007",

  TWO_FACTOR_ALREADY_ENABLE = "210008",

  NO_TWO_FACTOR_SECRET = "210009",

  VERIFICATION_EVENT_INVALID = "210010",

  SECURE_GOOGLE_AUTHENTICATOR_NEED_VERIFY = "210011",

  SECURE_EMAIL_NEED_VERIFY = "210012",

  SECURE_PHONE_NEED_VERIFY = "210013",
  
  SECURE_GOOGLE_AUTHENTICATOR_VERIFY_FAIL = "210014",

  SECURE_EMAIL_VERIFY_FAIL = "210015",
  CODE_VERIFY_FAIL_FOR_EXPIRE = "210017",
  CODE_VERIFY_FAIL_NOT_EXIST = "210018",

  SECURE_PHONE_VERIFY_FAIL = "210016",
  PHONE_CODE_VERIFY_FAIL_FOR_EXPIRE = "210020",
  PHONE_CODE_VERIFY_FAIL_NOT_EXIST = "210021",
  


  UNAUTHENTICATED_BY_DEVICE_CHANGE = "210019",

  EMAIL_VERIFY_CODE_ERROR = "220001",

  USER_EMAIL_EXIST = "230001",

  USER_LOGIN_FAIL = "230002",

  USER_LOGIN_CREDENTIALS_INCORRECT = "230003",
  // 用户不支持机构
  USER_NOT_SUPPORT_INSTITUTION = "230004",

  USER_STATUS_NO_ACCESS = "230005",

  USER_EMAIL_NOT_EXIST = "230006",

  USER_LOGIN_FAIL_LOCKED = "230007",

  USER_EXTEND_ERROR = "230008",

  AT_LEAST_OPEN_ONE_SECURE_SETTING = "230009",

  ACCOUNT_SETTING_SAVE_FAILED = "230010",
  GOOGLE_2FA_UNSET = "230011",
  PHONE_2FA_UNSET = "230016",
}
