interface IQuoteBuyParams {
  amount: number;
  pair: string;
}

interface IQuoteSellParams {
  quantity: number;
  pair: string;
}

interface IBuyOrderConfirmParams extends IQuoteBuyParams {}
interface ISellOrderConfirmParams extends IQuoteSellParams {
  sell_to: "balance" | "ach" | "bank" | "local_payments";
}

export const useQuoteBuyMutation = () => {
  return useMutation({
    mutationFn: (params: IQuoteBuyParams) =>
      fetchByPost<[{}]>("/quote/buy/amount", params, {
        capture: true,
      }),
  });
};

export const useQuoteSellMutation = () => {
  return useMutation({
    mutationFn: (params: IQuoteSellParams) =>
      fetchByPost<[{}]>("/quote/sell/quantity", params, {
        capture: true,
      }),
  });
};

export const useConfirmBuyOrderMutation = () => {
  return useMutation({
    mutationFn: (params: IBuyOrderConfirmParams) =>
      fetchByPost<[IConfirmTradeOrderData]>("/trade/buy/amount", params, {
        capture: true,
      }),
  });
};

export const useConfirmSellOrderMutation = () => {
  return useMutation({
    mutationFn: (params: ISellOrderConfirmParams) =>
      fetchByPost<[IConfirmTradeOrderData]>("/trade/sell/quantity", params, {
        capture: true,
      }),
  });
};
